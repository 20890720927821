<template>
    <main class="settings">
        <h2 class="sr-only">Настройки отчетов</h2>
        <div class="settings__tabs-nav">
            <router-link
                v-for="settingsItem in settingsItems"
                :key="settingsItem.name"
                class="settings__tabs-link link link_red h2"
                active-class="settings__tabs-link_active"
                :to="{ name: settingsItem.name }"
            >
                {{settingsItem.tabTitle}}
            </router-link>
        </div>
        <router-view class="settings__tab" />
    </main>
</template>

<script>
    import { mapGetters } from "vuex"

    export default {
        name: "Settings",
        computed: {
            ...mapGetters([
                "userMethods",
                "isAdmin"
            ]),
            settingsItems() {
                return this.$router.options.routes.filter(
                    ({ path }) => path === "/settings"
                )[0].children.filter(
                    ({ path }) => Boolean(path)
                ).map(
                    ({ name, meta: { tabTitle, requiresAdmin, requiredMethodURLs } }) => ({
                        name,
                        tabTitle,
                        requiresAdmin,
                        requiredMethodURLs
                    })
                ).filter(
                    ({ requiresAdmin, requiredMethodURLs }) => {
                        if (requiresAdmin) {
                            return this.isAdmin;
                        } else if (requiredMethodURLs) {
                            return requiredMethodURLs.every(
                                methodURL => this.userMethods?.URLs?.includes(methodURL)
                            );
                        }

                        return true;
                    }
                );
            }
        },
        methods: {
            redirectIfNeed(name) {
                if (name === "Settings") {
                    this.$router.push({
                        name: this.settingsItems?.length ? this.settingsItems[0].name : "401"
                    });
                }
            }
        },
        created() {
            this.redirectIfNeed(this.$route.name);
        },
        watch: {
            $route(route) {
                this.redirectIfNeed(route.name);
            }
        }
    }
</script>

<style lang="scss">
    .settings__tabs-nav {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;

        @include desktop {
            margin-bottom: 15px;
        }
    }
    .settings__tabs-link {
        text-align: left;
        text-decoration: underline;

        &:focus {
            outline: none;
        }

        & + & {
            margin-left: 10px;
        }

        &_active {
            color: inherit;
            text-decoration: none;
        }
    }
</style>
